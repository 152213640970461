import './App.css';
import HogRoutes from './Components/Routes';

function App() {
  return (
    <div className="App">
      <HogRoutes />
    </div>
  );
}

export default App;
